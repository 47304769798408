import { combineReducers } from "redux";
import errorReducer from "./errorReducer";
import fetchFuelReducer from "./fetchFuelReducer";
import fetchRealtimeReducer from "./fetchRealtimeReducer";
import fetchSummaryReducer from "./fetchSummaryReducer";
import fetchFuelSummaryReducer from "./fetchFuelSummaryReducer";
import fetchFuelModelReducer from "./fetchFuelModelReducer";
import fetchFuelCulpritReducer from "./fetchFuelCulpritReducer";
import fetchTicketReducer from "./fetchTicketReducer";
import fetchAppReducer from "./fetchAppReducer";
import fetchInstAttemptsReducer from "./fetchInstAttemptsReducer";
import fetchGsmGpsReducer from "./fetchGsmGpsReducer";
import fetchInstImageReducer from "./fetchInstImageReducer";
import fetchFirebaseDataReducer from "./fetchFirebaseDataReducer";
import fetchThresholdReducer from "./fetchThresholdReducer";
import daySummaryReducer from "./fetchDaySummaryReducer";
import loginReducer from "./loginReducer";
import fetchFuelTicketReducer from "./fetchFuelTicketReducer";
import fuelCloseTicketReducer from "./fuelTicketReducer";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: [
    "fuelReducer",
    "realtimeReducer",
    "summaryReducer",
    "fuelSummaryReducer",
    "fuelModelReducer",
    "fuelCulpritReducer",
    "ticketReducer",
    "appReducer",
    "instAttemptsReducer",
    "firebaseReducer",
    "thresholdReducer",
    "daySummData",
    "fuelTicketReducer",
    "fuelCloseTicketReducer"
  ],
};

const rootReducer = combineReducers({
  errorReducer,
  fuelReducer: fetchFuelReducer,
  realtimeReducer: fetchRealtimeReducer,
  summaryReducer: fetchSummaryReducer,
  fuelSummaryReducer: fetchFuelSummaryReducer,
  fuelModelReducer: fetchFuelModelReducer,
  fuelCulpritReducer: fetchFuelCulpritReducer,
  ticketReducer: fetchTicketReducer,
  appReducer: fetchAppReducer,
  instAttemptsReducer: fetchInstAttemptsReducer,
  gsmGpsReducer: fetchGsmGpsReducer,
  instImageReducer: fetchInstImageReducer,
  firebaseReducer: fetchFirebaseDataReducer,
  thresholdReducer: fetchThresholdReducer,
  daySummData: daySummaryReducer,
  fuelTicketReducer: fetchFuelTicketReducer,
  fuelCloseTicketReducer,
  loginReducer,
});

export default persistReducer(persistConfig, rootReducer);
