import { all } from "redux-saga/effects";
import fetchFuelSaga from "./fetchFuelSaga";
import fetchRealtimeSaga from "./fetchRealtimeSaga";
import fetchSummarySaga from "./fetchSummarySaga";
import fetchFuelSummarySaga from "./fetchFuelSummarySaga";
import fetchFuelModelSaga from "./fetchFuelModelSaga";
import fetchFuelCulpritSaga from "./fetchFuelCulpritSaga";
import fetchTicketSaga from "./fetchTicketSaga";
import fetchAppSaga from "./fetchAppSaga";
import fetchInstAttemptsSaga from "./fetchInstAttemptsSaga";
import fetchGsmGpsSaga from "./fetchGsmGpsSaga";
import fetchInstImageSaga from "./fetchInstImageSaga";
import fetchFirebaseDataSaga from "./fetchFirebaseDataSaga";
import fetchThresholdSaga from "./fetchThresholdSaga";
import daySummaryRequestSaga from "./fetchDaySummarySaga";
import loginRequestSaga, { logoutRequestSaga } from "./loginSaga";
import fetchFuelTicketSaga from "./fetchFuelTicketSaga";
import fuelCloseTicketSaga from "./fuelTicketSaga";

export default function* rootSaga() {
  yield all([
    fetchFuelSaga(),
    fetchRealtimeSaga(),
    fetchSummarySaga(),
    fetchFuelSummarySaga(),
    fetchFuelModelSaga(),
    fetchFuelCulpritSaga(),
    fetchTicketSaga(),
    fetchAppSaga(),
    fetchInstAttemptsSaga(),
    fetchGsmGpsSaga(),
    fetchInstImageSaga(),
    fetchFirebaseDataSaga(),
    fetchThresholdSaga(),
    daySummaryRequestSaga(),
    loginRequestSaga(),
    logoutRequestSaga(),
    fetchFuelTicketSaga(),
    fuelCloseTicketSaga()
  ]);
}
