import axios from "axios";
import { parseDayDetails } from "../utils";
import { API_ROUTES } from "../config";

const headerParams = { "app-version": 26.6 };

export const loginUser = async ({ email, password }) => {
  try {
    const { data } = await axios({
      method: "post",
      url: `${
        process.env.REACT_APP_INTERNALS ??
        "https://internal-support-portal.azurewebsites.net"
      }${API_ROUTES.LOGIN}`,
      data: JSON.stringify({ user_id: email, user_pwd: password }),
      headers: {
        "content-type": "application/json",
      },
    });
    return data;
  } catch (error) {
    console.err(error);
    throw error;
  }
};

export const fuels = async ({ requestData }) => {
  try {
    const { data } = await axios({
      method: "post",
      url: `https://production.carnot-traclytics.com${API_ROUTES.FUELS}`,
      data: requestData,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const realtime = async ({ requestDevice }) => {
  try {
    let device = requestDevice.device_id;
    const { data } = await axios({
      method: "post",
      url: `${
        process.env.REACT_APP_DATA ??
        "https://4gv48ymxwc.execute-api.us-east-2.amazonaws.com"
      }${API_ROUTES.REALTIME}${device}/`,
      data: requestDevice,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const deviceSummary = async ({ requestDevice }) => {
  try {
    let device = requestDevice.device_id;
    const { data } = await axios({
      method: "post",
      url: `${
        process.env.REACT_APP_DATA ??
        "https://4gv48ymxwc.execute-api.us-east-2.amazonaws.com"
      }${API_ROUTES.DEVICE_SUMMARY}${device}/`,
      data: requestDevice,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const deviceFuelSummary = async ({ requestDevice }) => {
  try {
    let device = requestDevice.device_id;
    const { data } = await axios({
      method: "post",
      url: `${
        process.env.REACT_APP_DATA ??
        "https://4gv48ymxwc.execute-api.us-east-2.amazonaws.com"
      }${API_ROUTES.DEVICE_FUEL_SUMMARY}${device}/`,
      data: requestDevice,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const deviceFuelModel = async ({ requestDevice }) => {
  try {
    let device = requestDevice.device_id;
    const { data } = await axios({
      method: "post",
      url: `${
        process.env.REACT_APP_DATA ??
        "https://4gv48ymxwc.execute-api.us-east-2.amazonaws.com"
      }${API_ROUTES.MODEL}${device}/`,
      data: requestDevice,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const deviceFuelCulprit = async ({ requestDevice }) => {
  try {
    let device = requestDevice.device_id;
    const { data } = await axios({
      method: "post",
      url: `${
        process.env.REACT_APP_DATA ??
        "https://4gv48ymxwc.execute-api.us-east-2.amazonaws.com"
      }${API_ROUTES.FUEL_CULPRIT}${device}/`,
      data: requestDevice,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const deviceTicket = async ({ requestDevice }) => {
  try {
    let device = requestDevice.device_id;
    const { data } = await axios({
      method: "post",
      url: `${
        process.env.REACT_APP_DATA ??
        "https://4gv48ymxwc.execute-api.us-east-2.amazonaws.com"
      }${API_ROUTES.DEVICE_TICKET}${device}/`,
      data: requestDevice,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const deviceApp = async ({ requestDevice }) => {
  try {
    let device = requestDevice.device_id;
    const { data } = await axios({
      method: "get",
      url: `https://production.carnot-traclytics.com${API_ROUTES.APP}${device}/`,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const deviceAttempts = async ({ requestDevice }) => {
  try {
    let device = requestDevice.device_id;
    const { data } = await axios({
      method: "post",
      url: `${
        process.env.REACT_APP_DATA ??
        "https://4gv48ymxwc.execute-api.us-east-2.amazonaws.com"
      }${API_ROUTES.INSTALLATION}${device}/`,
      data: requestDevice,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const deviceGsmGps = async ({ requestDevice }) => {
  try {
    let device = requestDevice.device_id;
    const { data } = await axios({
      method: "post",
      url: `${
        process.env.REACT_APP_DATA ??
        "https://4gv48ymxwc.execute-api.us-east-2.amazonaws.com"
      }${API_ROUTES.GSM_GPS}${device}/`,
      data: requestDevice,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const deviceInstImg = async ({ requestDevice }) => {
  try {
    let device = requestDevice.device_id;
    const { data } = await axios({
      method: "post",
      url: `${
        process.env.REACT_APP_DATA ??
        "https://4gv48ymxwc.execute-api.us-east-2.amazonaws.com"
      }${API_ROUTES.INSTALLATION_IMAGE}${device}/`,
      data: requestDevice,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const firebaseData = async ({ requestDevice }) => {
  try {
    let device = requestDevice.device_id;
    const { data } = await axios({
      method: "post",
      url: `${
        process.env.REACT_APP_INTERNALS ??
        "https://internaltools.carnot-traclytics.com"
      }${API_ROUTES.FIREBASE}${device}`,
      data: requestDevice,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const thresholdValue = async ({ requestDevice }) => {
  try {
    let device = requestDevice.device_id;
    const { data } = await axios({
      method: "post",
      url: `${
        process.env.REACT_APP_DATA ??
        "https://4gv48ymxwc.execute-api.us-east-2.amazonaws.com"
      }${API_ROUTES.THRESHOLD}${device}/`,
      data: requestDevice,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchDaySummary = async ({ requestData }) => {
  let deviceId = requestData.device_id;
  let date = new Date(requestData.date);
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let today = date.getDate();
  if (month < 10) {
    month = "0" + month;
  }

  if (today < 10) {
    today = "0" + today;
  }

  const { data } = await axios.get(
    `https://production.carnot-traclytics.com/data/summary/day/${deviceId}/${year}/${month}/${today}/0/`,
    { headers: headerParams }
  );

  return data;
};

export const fetchDayDetail = async ({ deviceId, date }) => {
  let datee = new Date(date);
  let month = datee.getMonth() + 1;
  let year = datee.getFullYear();
  let today = datee.getDate();

  if (month < 10) {
    month = "0" + month;
  }

  if (today < 10) {
    today = "0" + today;
  }

  const {
    data: { data },
  } = await axios.get(
    `https://production.carnot-traclytics.com/data/details/day/${deviceId}/${year}/${month}/${today}/0/`,
    { headers: headerParams }
  );
  return parseDayDetails(data);
};

export const getFuelTicket = async ({ requestTicket }) => {
  const { data } = await axios.get(
    `https://production.carnot-traclytics.com${API_ROUTES.FUEL_TICKET}${requestTicket.id}/`,
  );

  return data
};

export const closeFuelTicket = async ({ requestCloseFuelTicket }) => {
  try {
    const { data } = await axios({
      method: "post",
      url: `https://production.carnot-traclytics.com${API_ROUTES.CLOSE_TICKET}`,
      data: requestCloseFuelTicket,
    });
    return data;
  } catch (error) {
    throw error;
  }
};
